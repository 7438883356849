@import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/initial-variables";

$alpha: 0.85;

//$primary: rgb(255, 180, 33);
$primary: rgb(255, 186, 64);
$primary-invert: white;
$sky-blue: #5B78F3;
$sky-blue-light: #ECF0FE;
$blue: #4565FB;
//noinspection CssInvalidFunction
//$primary-invert: findColorInvert($primary);

$primary-light: transparentize($primary, $alpha);
$purple-dark: #230F40;
$violet-light: #8378EA;
$competition-color: #F4EE1F;
$white-gray: #F5F6FA;
$primary-light-invert: $primary;

$light: rgb(181, 181, 181);
$light-gray: #B9B9B9;
//$light: rgb(213, 213, 213);
//noinspection CssInvalidFunction
$light-invert: findColorInvert($light);

//noinspection SpellCheckingInspection
$light-light: transparentize($light, $alpha);
$light-light-invert: rgb(130, 130, 130);

//$info: rgb(65, 160, 218);
$info: rgb(26, 92, 255);
//noinspection CssInvalidFunction
$info-invert: findColorInvert($info);

//noinspection SpellCheckingInspection
$info-light: transparentize($info, $alpha);
$info-light-invert: $info;

//$success: rgb(115, 214, 143);
//$success: rgb(94, 195, 93);
//$success: rgb(35, 209, 96);
$success: rgb(70, 201, 58);
//noinspection CssInvalidFunction
$success-invert: findColorInvert($success);

//noinspection SpellCheckingInspection
$success-light: transparentize($success, $alpha);
$success-light-invert: $success;

//$warning: rgb(255, 208, 87);
$warning: rgb(255, 186, 0);
//noinspection CssInvalidFunction
$warning-invert: findColorInvert($warning);

//noinspection SpellCheckingInspection
$warning-light: transparentize($warning, $alpha);
$warning-light-invert: $warning;

//$danger: rgb(255, 64, 64);
$danger: rgb(255, 71, 87);
//noinspection CssInvalidFunction
$danger-invert: findColorInvert($danger);

//noinspection SpellCheckingInspection
$danger-light: transparentize($danger, $alpha);
$danger-light-invert: $danger;

$purple: rgb(56, 45, 90);
//noinspection CssInvalidFunction
$purple-invert: findColorInvert($purple);
$purple-light: transparentize($purple, $alpha);
$purple-light-invert: $purple;

$turquoise: #4DC0D9;
$turquoise-invert: findColorInvert($turquoise);
$turquoise-light: transparentize($turquoise, $alpha);
$turquoise-light-invert: $turquoise;

$smoke: #F8F8F8;
//noinspection CssInvalidFunction
$smoke-invert: findColorInvert($smoke);
$smoke-light: transparentize($smoke, $alpha);
$smoke-light-invert: $smoke;

//Dashboard
$prepaid-header: #382d5a;

// Select
$border-light: #E8E8E8;

%textRadialGradient {
  background: radial-gradient(50% 50% at 50% 50%, #F7DD9B 0%, #F8CD59 0.01%, #FFF237 48.23%, #F4EF1F 77.4%, #FFBF1C 100%);
  //noinspection CssUnknownProperty
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

//noinspection CssInvalidPseudoSelector,CssUnknownProperty
:export {
    light: $light;
    lightInvert: $light-invert;
    lightButton: $light-light;
    lightButtonInvert: $light-light-invert;
    info: $info;
    infoInvert: $info-invert;
    infoButton: $info-light;
    infoButtonInvert: $info-light-invert;
    success: $success;
    successInvert: $success-invert;
    successButton: $success-light;
    successButtonInvert: $success-light-invert;
    warning: $warning;
    warningInvert: $warning-invert;
    warningButton: $warning-light;
    warningButtonInvert: $warning-light-invert;
    danger: $danger;
    dangerInvert: $danger-invert;
    dangerButton: $danger-light;
    dangerButtonInvert: $danger-light-invert;
    primary: $primary;
    primaryInvert: $primary-invert;
    primaryButton: $primary-light;
    primaryButtonInvert: $primary-light-invert;
    violetLight: $violet-light;
    prepaidHeader: $prepaid-header;
    purple: $purple;
    purpleInvert: $purple-invert;
    purpleButton: $purple-light;
    purpleButtonInvert: $purple-light-invert;
    turquoise: $turquoise;
    turquoiseLight: $turquoise-light;
    turquoiseInvert: $turquoise-invert;
    turquoiseButton: $turquoise-light;
    turquoiseButtonInvert: $turquoise-light-invert;
    borderLight: $border-light;
}

