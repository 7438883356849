$main-font: "Rubik";
$family-monospace: "Fira Mono";

/* Default font */
//noinspection SassScssUnresolvedVariable
$body-family: $main-font, $family-sans-serif;


:export {
    mainFont: $main-font;
    bodyFonts: $body-family;
    monospaced: $family-monospace;
}
