
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

















































































































































































































































$color-success: #00855B !important;
$background-button: #fff !important;
$color-button: #FF5866 !important;
$analytic-width: 13rem;

::v-deep {
    .custom-button-wrapper {
        position: absolute;
        top: 0;
        right: 0;
    }

    .custom-button {
        background-color: $background-button;
        border-radius: 0 0 0 7px !important;
    }
}

.analytic {
    min-width: $analytic-width;
    position: relative;

    & > * {
        display: flex;
        align-items: center;
        white-space: nowrap;
    }
}

.tag {
    &.is-success {
        color: $color-success;
        background: #E3F8EF;
    }
}

.difference.has-text-success {
    color: $color-success;
}
